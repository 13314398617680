import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css'
import { Navbar } from './components/Navbar'
import { About } from './components/pages/about'
import { Contact } from './components/pages/contact'
import { Topics } from './components/pages/topics'
import { Home } from './components/pages/home'
import { FAQS } from './components/pages/faq'

function App () {
  return (
      <div className='App'>
        <Navbar />

        <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/topics' element={<Topics />} />
        <Route path='/faqs' element={<FAQS />} />
        {/* Catch-all route */}
        <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </div>
    )
  }

export default App

import React, { useState } from 'react'
import $ from 'jquery';

export const Contact = () => {
  const [email, setEmail] = useState('')
  const [question, setQuestion] = useState('')

  function isValidEmail(email) {
    // Define a regular expression for validating email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regular expression
    return emailRegex.test(email);
  }

  async function postDataToAWS(email, subject, message) {
    const url = 'https://yznp4o0oy9.execute-api.us-east-1.amazonaws.com/prod/api/MOgetPost'; // Replace with your API Gateway URL
    const data = {
        email: email,
        subject: subject,
        message: message
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*' 
            },
            mode: 'no-cors', // Set the request mode to 'no-cors'
            body: JSON.stringify(data),
        });

        if (response.ok) {
            const result = await response.json();
            console.log('Response from AWS:', result);
        } else { //currently response from the lambda lands here
            //console.log('Request sent');  
            if (subject === "question") $('#questionSubmittedMsg').html("Message sent, thank you.").css("color","green");
            else if (subject === "access")  $('#accessRequestedMsg').html("Request sent, thank you.").css("color","green");
        }
    } catch (error) {
        console.error('Network error:', error);
    }
}

  const handleQuestionSubmit = e => {
    e.preventDefault()

    if (email === ""){
      $('#questionSubmittedMsg').html("Please enter your email address.").css("color","red");
    } else if (! isValidEmail(email)) {
      $('#questionSubmittedMsg').html("Check your email for errors.").css("color","red");
    } else if (question === ""){
      $('#questionSubmittedMsg').html("Please enter a question or message.").css("color","red");
    }
      else {
    let message = question;
    let subject = "question";
    postDataToAWS(email, subject, message);
    }
  }

  const handleEmailSubmit = e => {
    e.preventDefault();

    if (email === ""){
      $('#accessRequestedMsg').html("Please enter your email address.").css("color","red");
    } else if (! isValidEmail(email)) {
      $('#accessRequestedMsg').html("Check your email for errors.").css("color","red");
    } 
      else {
      let message = "none";
      let subject = "access";
      postDataToAWS(email, subject, message);
    }
  }

  return (
    <div
      style={{
        position: 'relative',
        top: '100px',
        backgroundColor: 'rgb(244, 244, 244)',
        height: '100%',
        overflowY: 'auto'
      }}
    >
      <table
        style={{
          position: 'relative',
          top: '60px',
          left: '-30px',
          width: '100%',
          textAlign: 'center'
        }}
      >
        <tbody>
          <tr style={{ border: 'solid thin black' }}>
            <td style={{ width: '15%' }}>&nbsp;</td>
            <td
              style={{
                width: '70%',
                paddingBottom: '5px',
                textAlign: 'left',
                border: 'solid thin transparent'
              }}
            >
              Enter your email to{' '}
              <span style={{ fontWeight: 'bold' }}>request access</span> to the MenloOaksVoices@Groups.io
              discussion forum:
            </td>
            <td style={{ width: '15%' }}>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td
              style={{ textAlign: 'center', border: 'solid thin transparent' }}
            >
              <input
                type='email'
                id='email'
                name='email'
                onChange={e => setEmail(e.target.value)}
                style={{ width: '100%', marginLeft: '0px' }}
                placeholder='Enter your email'
                required
              ></input>
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td style={{ paddingTop: '5px', border: 'solid thin transparent' }}>
              <span id='accessRequestedMsg' style={{fontFamily:'serif'}}></span>
              <button id='submitAccess' onClick={handleEmailSubmit}>
                Request Access
              </button>
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr style={{ height: '70px' }}>
            <td colSpan='3'>&nbsp;</td>
          </tr>
          <tr style={{ border: 'solid thin black' }}>
            <td style={{ width: '20%' }}>&nbsp;</td>
            <td
              style={{
                width: '60%',
                paddingBottom: '5px',
                textAlign: 'left',
                border: 'solid thin transparent'
              }}
            >
              Enter your email and your{' '}
              <span style={{ fontWeight: 'bold' }}>question or message </span>
              for the site moderators:
            </td>
            <td style={{ width: '20%' }}>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td
              style={{ textAlign: 'center', border: 'solid thin transparent' }}
            >
              <input
                type='email'
                id='email'
                name='email'
                onChange={e => setEmail(e.target.value)}
                style={{ width: '100%', marginLeft: '0px' }}
                placeholder='Enter your email'
                required
              ></input>
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
          <td colSpan='3'>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td
              style={{ textAlign: 'center', border: 'solid thin transparent' }}
            >
              <textarea
                id='question'
                name='question'
                rows='8'
                style={{ width: '100%' }}
                onChange={e => setQuestion(e.target.value)}
                placeholder='Enter your question or statement'
              ></textarea>
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td style={{ paddingTop: '1px', border: 'solid thin transparent' }}>
              <span id='questionSubmittedMsg' style={{fontFamily:'serif'}}></span>
              <button id='submitQuestion' onClick={handleQuestionSubmit}>
                Send to Moderators
              </button>
            </td>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </table>
      <div style={{border: 'solid thin transparent', height:'160px'}}>&nbsp;</div> {/* to trigger scrollbar */}
    </div>
)};

import React from 'react'
import {NavLink, Link} from 'react-router-dom';
import "./Navbar.css";

export const Navbar = () => { 
    return <nav>
            <Link to="/" className="Title">Menlo Oaks Voices</Link>
             <ul style={{flexWrap: 'wrap'}}>
                <li><NavLink to="/topics">Topics</NavLink></li>
                <li><NavLink to="/contact">Contact</NavLink></li>
                <li><NavLink to="/faqs">FAQs</NavLink></li>
                <li><NavLink to="/about">About</NavLink></li>
             </ul>
        </nav>
}
import React from 'react'

export const FAQS = () => {
  return (
    <div>
      <div
        style={{
          position: 'relative',
          overflowY: 'auto',
          top: '80px',
          lineHeight: '1.5',
          display: 'flex',
          justifyContent: 'center',
          margin: 'auto',
          height: '100vh',
          padding: '30px'
        }}
      >
        <div style={{ margin: 'auto' }}>
          <div class='col-sm-12'>
            <span class='wikidisplay user-content'>
              <p
                style={{
                  textAlign: 'center',
                  paddingLeft: '0px',
                  marginTop: '20px'
                }}
              >
                <strong>FREQUENTLY ASKED QUESTIONS ABOUT GROUPS.IO </strong>
              </p>
            </span>
            <p>&nbsp;</p>
            <p style={{ paddingBottom: '8px' }}>
              <span style={{ color: '#236fa1' }}>
                <strong>Q</strong>: How can I{' '}
                <strong>turn off notifications to my email inbox</strong>?&nbsp;
                Can I visit the website instead to see my messages?
              </span>
            </p>
            <p style={{ paddingLeft: '40px' }}>
              <span style={{ color: '#169179' }}>
                <strong>A</strong>: It's easy -- this image shows how you can
                select "no email" in your personal settings.
              </span>
            </p>
            <br></br>
            <p style={{ paddingLeft: '40px' }}>
              <img
                src='groups.io.setNoEmails.png'
                width='647'
                height='284'
                class='myimg-responsive'
              ></img>
            </p>

            <p style={{ paddingLeft: '40px' }}>&nbsp;</p>
            <hr></hr>
            <br></br>
            <p style={{ paddingBottom: '8px' }}>
              <span style={{ color: '#236fa1' }}>
                <strong>Q:</strong>&nbsp;What if I want to get{' '}
                <strong>notifications on some topics but not others</strong>?
              </span>
            </p>
            <p style={{ paddingLeft: '40px' }}>
              <span style={{ color: '#169179' }}>
                <strong>A</strong>: You can mute the topics you aren't
                interested in, and leave the others unmuted.&nbsp; Here's how:
              </span>
            </p>
            <br></br>
            <p style={{ paddingLeft: '40px' }}>
              <span style={{ color: '#169179' }}>1. Select a topic</span>
              <br></br>
              <span>
                <img
                  style={{ marginTop: '6px' }}
                  src='Select_a_topic.png'
                  width='511'
                  height='247'
                  class='myimg-responsive'
                ></img>
              </span>
            </p>
            <p style={{ paddingLeft: '40px' }}>&nbsp;</p>
            <p style={{ paddingLeft: '40px' }}>
              <span style={{ color: '#169179' }}>2. Click "Mute"</span>
              <br></br>
              <span>
                <img
                  style={{ marginTop: '6px' }}
                  src='clickOrangeMuteButton.png'
                  width='510'
                  height='246'
                  class='myimg-responsive'
                ></img>
              </span>
            </p>
            <p style={{ paddingLeft: '40px' }}>&nbsp;</p>
            <p style={{ paddingLeft: '40px' }}>
              <span style={{ color: '#169179' }}>3. "Unmute" at any time</span>
              <br></br>
              <span>
                <img
                  style={{ marginTop: '6px' }}
                  src='mute-to-unmute.png'
                  width='506'
                  height='244'
                  class='myimg-responsive'
                ></img>
              </span>
            </p>
            <p style={{ paddingLeft: '40px' }}>&nbsp;</p>
            <hr></hr>
            <br></br>
            <p style={{ paddingBottom: '8px' }}>
              <span style={{ color: '#236fa1' }}>
                <strong>Q:</strong> Can I <strong>change a message</strong> that
                I <strong>already posted</strong>?
              </span>
            </p>
            <p style={{ paddingLeft: '40px' }}>
              <span style={{ color: '#169179' }}>
                <strong>A</strong>: Yes you can.&nbsp;{' '}
              </span>
            </p>
            <br></br>
            <p style={{ paddingLeft: '40px' }}>
              <span style={{ color: '#169179' }}>
                1. Navigate to the message you wish to edit.
              </span>
            </p>
            <p style={{ paddingLeft: '40px' }}>
              <span style={{ color: '#169179' }}>
                <img
                  style={{ marginTop: '6px' }}
                  src='nicerUI.png'
                  width='594'
                  height='124'
                  class='myimg-responsive'
                ></img>
              </span>
            </p>
            <p style={{ paddingLeft: '40px' }}>&nbsp;</p>
            <p style={{ paddingLeft: '40px' }}>
              <span style={{ color: '#169179' }}>
                2. Click "More" (bottom right of the message frame) and select
                "Edit Message" from the list.
              </span>
            </p>
            <p style={{ paddingLeft: '40px' }}>
              <span style={{ color: '#169179' }}>
                <img
                  style={{ marginTop: '6px' }}
                  src='MorePickList.png'
                  width='601'
                  height='127'
                  class='myimg-responsive'
                ></img>
              </span>
            </p>
            <p style={{ paddingLeft: '40px' }}>&nbsp;</p>
            <p style={{ paddingLeft: '40px' }}>
              <span style={{ color: '#169179' }}>
                3. Make your changes using the editing tools.
              </span>
            </p>
            <p style={{ paddingLeft: '40px' }}>
              <span style={{ color: '#169179' }}>
                <img
                  style={{ marginTop: '6px' }}
                  src='editMessage.png'
                  width='598'
                  height='194'
                  class='myimg-responsive'
                ></img>
              </span>
            </p>
            <p style={{ paddingLeft: '40px' }}>&nbsp;</p>
            <p style={{ paddingLeft: '40px' }}>
              <span style={{ color: '#169179' }}>
                4. Beneath the editing frame, click the green "Save And Send To
                Group" button.
              </span>
            </p>
            <p style={{ paddingLeft: '40px' }}>
              <span style={{ color: '#169179' }}>
                <img
                  style={{ marginTop: '6px' }}
                  src='saveAndSend.png'
                  width='511'
                  height='342'
                  class='myimg-responsive'
                ></img>
              </span>
            </p>
            <p style={{ paddingLeft: '40px' }}>&nbsp;</p>
            <hr></hr>
            <br></br>
            <p
              style={{
                color: '#333333',
                fontFamily: 'sans-serif',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '400',
                textAlign: 'start',
                marginBottom: '6px'
              }}
            >
              <span style={{ color: '#236fa1' }}>
                <strong>Q:</strong>
                <span>
                  {' '}
                  Is there a <strong>more modern interface</strong> than the
                  email interface available <strong>for a cell phone</strong>
                </span>
                ?
              </span>
            </p>
            <p
              style={{
                color: '#333333',
                fontFamily: 'sans-serif',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '400',
                textAlign: 'start'
              }}
            >
              <span
                style={{
                  color: '#169179',
                  marginLeft: '40px',
                  marginTop: '10px'
                }}
              >
                <strong>A</strong>: Yes.&nbsp; Visit your app store on iPhone or
                Android and install the Groups.io app.&nbsp; It has a modern
                user interface and is easy to use.
              </span>
            </p>
            <p
              style={{
                color: '#333333',
                fontFamily: 'sans-serif',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '400',
                textAlign: 'start',
                paddingLeft: '40px'
              }}
            >
              &nbsp;
            </p>
            <p
              style={{
                color: '#333333',
                fontFamily: 'sans-serif',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '400',
                textAlign: 'start',
                paddingLeft: '40px'
              }}
            >
              &nbsp;
            </p>
          </div>

          <hr></hr>
          <div style={{ height: '150px' }}>&nbsp;</div>
          {/* to trigger scrollbar */}
        </div>

        <div style={{ height: '150px' }}>&nbsp;</div>
        {/* to trigger scrollbar */}
      </div>
    </div>
)};

import React from "react";
import {Link} from 'react-router-dom';

export const Home = () =>{
    return <div>
    <div style={{
        position: 'relative',
        overflowY: 'auto',
        top: '80px',
        lineHeight: '1.5',
        display: 'flex',
        justifyContent: 'center',
        margin: 'auto',
        height: '100vh',
        padding: '30px'
    }}><div style={{marginRight: '10px',paddingTop:'20px'}}>
    <span style={{fontWeight:'bold'}}>Who we are</span>
    <br></br>
    We are Menlo Oaks Neighbors who want to bring the neighborhood together through
    better communication.    <br></br><br></br>
    <span style={{fontWeight:'bold'}}>Background</span>
    <br></br>
    Menlo Oaks is approximately 280 homes in an unincorporated area of San Mateo
    County. Many of us remember the MODA listserv that for many years enabled us to
    easily communicate with neighbors. Because we miss this forum, several of us have
    created a new website called MenloOaksVoices.com, and it links to a modern online
    message service that will let us communicate with each other more easily.<br></br><br></br>
    <span style={{fontWeight:'bold'}}>Goals</span>
    <br></br>
    The goals for the website and online message service are to enable improved
    communication for the common good of the neighborhood and to help us become more
    cohesive and supportive of one another.<br></br><br></br>
    <span style={{fontWeight:'bold'}}>For Everyone In Menlo Oaks</span>
    <br></br>
    The website and online message service are free of charge, ad free, tracker free, and open to anyone 
    who owns a home or lives in Menlo Oaks. Paid membership in MODA is not required.<br></br><br></br>
    <span style={{fontWeight:'bold'}}>How To Use The Online Message Service</span>
    <br></br><br></br>
    <span>We expect people in Menlo Oaks will use this service in many ways, for example:</span>   

     <div style={{marginLeft:'30px'}}>
     <ul>
        <li><i>Items for sale or free</i></li>
        <li><i>Items wanted to borrow or buy</i></li>
        <li><i>Services offered or wanted (e.g., babysitting, elder care)</i></li>
        <li><i>Safety announcements (e.g., break-ins, road work in the area)</i></li>
        <li><i>Offers to pick up groceries for those without transportation</i></li>
        <li><i>Recommendations for workers (e.g., home repairs, electricians, plumbers,
            gardeners, etc.)</i></li>
        <li><i>Setting up groups, like ones for gardening, woodworking, car pools, or wine
        tasting</i></li>
        <li><i>Useful tips (e.g., how to report a pothole to the County)</i></li>
        <li><i>Announcements about social events such as a neighborhood barbecue or art
        show</i></li>
        <li><i>Discussions about tree care</i></li>
        <li><i>Discussions about our infrastructure</i></li>
        <li><i>Notices and discussions about CERT (Community Emergency Response
            Team) training and relief services</i></li>
        <li><i>Discussions about proposed municipal actions or development projects
        relevant to Menlo Oaks</i></li>
    </ul> 
    </div>
    <br></br>
    The online message service is an open forum, and it is NOT intended for political or
    mean-spirited commentary. In fact, every post should pass the test of kindness,
    truthfulness, and usefulness. Otherwise, it does not belong here. Our online message
    service will be moderated, and abusers will be excluded.    <br></br><br></br>
    <span style={{fontWeight:'bold'}}>How It Works:</span>
    <br></br>
    <div style={{marginLeft:'30px'}}>
    <ul>
        <li><span style={{fontWeight:'bold'}}>How Do I Sign Up?</span>&nbsp;Click <Link to="/contact">contact</Link>
            &nbsp;to view the "Contact" page where you can enter your email. Then click "Request Access". 
            Expect an email invitation to join MenloOaksVoices@groups.io within a day or two. Within the email there will
            be a link that says "accept the invitation." To get an overview of what you will see when you click that link,
            have a look at the video on the <Link to="/topics">"Topics"</Link>&nbsp;page.</li>
        <li><span style={{fontWeight:'bold'}}>What are the key features of Groups.io?</span>
                <ul style={{marginLeft:'30px'}}>
                    <li><span style={{fontWeight:'bold'}}>Email-Based Communications:</span>&nbsp;Supports email-based conversations where users can send and receive messages through email, similar to traditional listservs.</li>
                    <li><span style={{fontWeight:'bold'}}>Threaded Conversations:</span>&nbsp;Messages are organized into threads, allowing users to follow and contribute to specific discussion topics easily.</li>
                    <li><span style={{fontWeight:'bold'}}>Searchable Archives:</span>&nbsp;Provides a searchable archive of all past discussions, making it easy to find and reference previous messages.</li>
                    <li><span style={{fontWeight:'bold'}}>Topic-Based Subgroups:</span>&nbsp;Supports the creation of subgroups for focused discussions on specific topics within the main group.</li>
                    <li><span style={{fontWeight:'bold'}}>Reply-to-Author or Reply-to-Group:</span>&nbsp;Users can choose to reply directly to the message author or to the entire group, facilitating both private and public discussions.</li>
                    <li><span style={{fontWeight:'bold'}}>Customizable Notification Preferences:</span>&nbsp;Users can set preferences for receiving notifications on new messages or replies to specific threads they are interested in.</li>
                </ul>
        </li>        
        <li><span style={{fontWeight:'bold'}}>Is there a quick way up the learning curve?</span>&nbsp;The <Link to="/topics">"Topics"</Link>&nbsp;page includes a Youtube video you may find helpful.</li>
        <li><span style={{fontWeight:'bold'}}>Privacy and Safety:</span>&nbsp;Because all discussions and information sharing will occur via Groups.io, participants will become members of Groups.io, and subject to its "terms of use", 
        agreed to as part of the sign-up process. Here are their <a href="https://groups.io/static/tos"  target="_blank">Terms of Service</a> which addresses privacy and safety.</li>
     </ul>   
     </div>
    <div style={{height: '150px'}}>&nbsp;</div>{/* to trigger scrollbar */}
    </div>
   </div> 
</div>
};
import React from "react";


function openInNewTab () {
    window.open("https://groups.io/g/MenloOaksVoices", '_blank').focus();
}

function isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check for common mobile user agents
    const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone|Opera Mini|IEMobile|Mobile/;

    return mobileRegex.test(userAgent);
}

export const Topics = () =>{
    let iFrameMargin = "0px";
    if (isMobile()) iFrameMargin = "-25px";
    return <div 
       style={{
                position: 'relative',
                top: '100px',
                overflowX: 'auto', 
                padding: '50px', 
                height: '95%',
                width: '100%',
                textAlign: 'center',
                verticalAlign: 'middle'}}>

        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <button className="button-10"
                style={{
                    fontSize: '16px',
                    background: '#1d4ed8',
                    height: '50px',
                    width: '250px',
                    lineHeight: '1.3',
                    paddingTop: '16px',
                    paddingBottom: '16px'
                }} 
            onClick={openInNewTab}>
{/*                 DISCUSSION TOPICS
                <br></br>
                (Groups.io)
                 */}
                MenloOaksVoices@groups.io
                </button>
        </div>
        <br></br>         
        <div>You will initially need an invitation to view or participate.</div> 
        <br></br>
        <div>Use the 'Contact' menu to request an invitation.</div>  
        <br></br> <br></br>
         <hr style={{border: 'none', height: '1px', backgroundColor: '#AAA', width: '30%', margin: 'auto' }}></hr>
        <br></br> <br></br>
        
        <div style={{textAlign: 'center', margin:'auto', width: '100%'}}>If you wish to learn more about using Groups.io, which provides our discussion service, you may find the following Youtube video helpful. 
            It was intended for a Florida Astronomical Society but it is an informative short introduction to how Groups.io works.</div> 
        <br></br>
        <div  style={{textAlign: 'center', marginLeft: iFrameMargin}}>
        {isMobile() ? 
        <iframe width="280" height="158" src="https://www.youtube.com/embed/rFdnCdQ-wEU?si=ZlT_oE7xtBSUkPvR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        : 
        <iframe width="560" height="315" src="https://www.youtube.com/embed/rFdnCdQ-wEU?si=ZlT_oE7xtBSUkPvR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        }
        </div>
        <br></br> <br></br>
        {/* <hr style={{border: 'none', height: '1px', backgroundColor: '#1d4ed8' }}></hr> */} 
{/*         <div>
            <br></br> <br></br>
            <div><strong>FREQUENTLY ASKED QUESTIONS and TIPS</strong></div>
            <br></br> <br></br>
        </div> */}
        <div style={{height: '50px'}}>&nbsp;</div>{/* to trigger scrollbar */}
    </div>
};